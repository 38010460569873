import React from 'react'
import { TransitionState } from 'gatsby-plugin-transition-link'

import Layout from 'layouts'
import VerticalScroll from 'components/layout/VerticalScroll'
import { Container, Section } from 'components/layout'
import Spacer from 'components/ui/Spacer'

import { Headline1, Headline2, Headline3, Headline4, Subtitle, BodyCopy } from 'components/ui/Typography'

const Typography = () => {
  return (
    <>
      <TransitionState>
        {({ mount, transitionStatus, current: { state } }) => {
          console.log('TYPOGRAPHY', 'TS', mount, transitionStatus, state.to, state.from)
          return (
            <Layout>
              <VerticalScroll>
                <Section>
                  <Container>
                    <Headline1>H1 Headline, lorem ipsum dolor sit amet</Headline1>
                    <Spacer initial="3rem" medium="5vw" />
                    <Headline2>
                      H2 Headline, lorem ipsum dolor sit amet, consectetur adipiscing elit vivamus vel mauris in orvelit
                    </Headline2>
                    <Spacer initial="3rem" medium="5vw" />
                    <Headline3>
                      H3 Headline, lorem ipsum dolor sit amet, consectetur adipiscing elit vivamus vel mauris in orvelit{' '}
                    </Headline3>
                    <Spacer initial="3rem" medium="5vw" />
                    <Headline4>
                      H4 Headline, lorem ipsum dolor sit amet, consectetur adipiscing elit vivamus vel mauris in orvelit{' '}
                    </Headline4>
                    <Spacer initial="3rem" medium="5vw" />
                    <div style={{ position: 'relative', height: '10vw' }}>
                      <Spacer horizontal max="20vw" min="5rem" />
                      <div style={{ display: 'inline-block' }}>
                        <Subtitle>Category</Subtitle>
                        <Subtitle vertical>Subtitle</Subtitle>
                      </div>
                    </div>
                    <Spacer initial="3rem" medium="5vw" />
                    <BodyCopy>
                      The world seems to be about as wondrous as it is heart-breaking. Both profoundly prosperous, and
                      deeply divided. Both technologically advanced and unbelievably unequal. But the way we see it—
                      there’s never been a better time to put creativity to work.{' '}
                    </BodyCopy>
                  </Container>
                </Section>
              </VerticalScroll>
            </Layout>
          )
        }}
      </TransitionState>
    </>
  )
}

export default Typography
